import React from 'react';
import Contact from '../components/contact';
import Layout from '../components/layout';
import Meta from '../components/meta';

export default function Index() {
  return (
    <Layout>
      <Meta></Meta>
      <Contact></Contact>
    </Layout>
  )
}
